import { computed, ComputedRef } from 'vue'
import { AsfCustomValidationMessages, AsfCustomValidationRules, AsfInputProps } from '@ui/types'

export function getValidationRules(props: AsfInputProps) {
  const { t } = useI18n()

  const customErrorMessages: ComputedRef<AsfCustomValidationMessages> = computed(() => {
    const errorMessages = {
      ...props.customMessages
    }
    if (props.type === 'password') {
      errorMessages.min = t('forms.errors.password.length', { count: 8 })
    }

    return errorMessages
  })

  const rules: ComputedRef<Record<string, boolean | string>> = computed(() => {
    const resultRules: AsfCustomValidationRules = {}

    if (props.required) {
      resultRules.required = true
    }

    if (props.type === 'email') {
      resultRules.email = true
    }

    if (props.type === 'password') {
      resultRules.min = { length: 8 }
      resultRules.upperCaseAmount = { min: 1 }
      resultRules.lowerCaseAmount = { min: 1 }
      resultRules.numbersAmount = { min: 1 }
      resultRules.specialCharsAmount = { min: 1 }
    }

    if ('minlength' in props && 'maxlength' in props && props.minlength === props.maxlength) {
      resultRules.length = { length: props.minlength }
    } else {
      if (props.minlength) {
        resultRules.min = { length: props.minlength }
      }

      if (props.maxlength) {
        resultRules.max = { length: props.maxlength }
      }
    }

    if (props.pattern) {
      resultRules.regex = props.pattern
    }

    return {
      ...resultRules,
      ...props.validationRules
    }
  })

  return { customErrorMessages, rules }
}
