<template>
  <div class="asf-input__password-strength">
    <div class="asf-input__password-strength__inner">
      <ul class="asf-input__password-strength__bar" :data-score="score">
        <li
          v-for="i in 5"
          :key="`item-${i}`"
          class="asf-input__password-strength__item"
          :class="[modifier && i - 1 <= (score || 0) && `m-${modifier}`]"
        />
      </ul>
      <span v-if="description" class="asf-input__password-strength__text">{{ description }}</span>
    </div>
    <div class="asf-input__password-strength__caption">
      <span>{{ captionStrength }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { TranslateResult } from 'vue-i18n'
import { computed, ComputedRef, defineComponent, onMounted, PropType } from 'vue'

type ComponentProps = {
  caption: TranslateResult | string
  password: string
}

const nameScore = (score: number | null): string | null => {
  switch (score) {
    case 0:
      return 'risky'
    case 1:
      return 'guessable'
    case 2:
      return 'weak'
    case 3:
      return 'safe'
    case 4:
      return 'secure'
    default:
      return null
  }
}

export default defineComponent({
  name: 'AsfPasswordStrength',
  props: {
    caption: { type: String as PropType<ComponentProps['caption']>, required: true },
    password: { type: String as PropType<ComponentProps['password']>, required: true }
  },
  setup(props: ComponentProps) {
    const { t } = useI18n()
    let passwordEstimator: typeof import('zxcvbn') | null = null
    const score = computed(() => {
      if (props.password && passwordEstimator) {
        return passwordEstimator(props.password).score
      }

      return null
    })

    const modifier: ComputedRef<string | null> = computed(() => nameScore(score.value))
    const description: ComputedRef<TranslateResult | null> = computed(() => {
      if (typeof modifier.value !== 'string') {
        return null
      }

      return t(`forms.passwordStrength.${modifier.value}`)
    })
    const captionStrength: ComputedRef<TranslateResult> = computed(
      () => props.caption || t('forms.passwordStrength.caption')
    )

    onMounted(() => {
      import(/* webpackChunkName: 'zxcvbn' */ 'zxcvbn').then((zxcvbn) => {
        passwordEstimator = zxcvbn.default
      })
    })

    return { score, modifier, description, captionStrength }
  }
})
</script>
<style lang="postcss">
@import '@components/atoms/Input/internal/PasswordStrength.css';
</style>
